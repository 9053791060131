import React from 'react';
import style from './auth.module.scss';
import './auth.module.scss';
import AbstractPage from '../AbstractPage';
import Modal from '../../organisms/modal/modal';
import {login} from '../../../auth';

export default class auth extends AbstractPage {
    constructor(props) {
        super(props)
        this.state = {
            username: "",
            password: "",
            action: "login"
        }
    }

    async componentDidMount() {
        await this.init()
    }

    handleInput(e) {
        let key = e.getAttribute('name')
        this.setState({[key]: e.value})
    }

    async handleSubmit(e) {
        e.preventDefault()

        const username = this.state.username
        const password = this.state.password

        if (username !== "" && password !== "") {

            try {
                const response = await this.api.post_auth(username, password)
                login(response.data.access_token);
                window.location.href = "/"
                return true
            } catch (e) {
                this.modalClick2()
                return false
            }
        }
        this.modalClick1()
        return false
    }

    async handleRecover(e) {
        e.preventDefault();
        const username = this.state.username
        if (username !== "") {
            try {
                await this.api.post_recover(username)
            } catch (e) {}

            this.modalRecoverSuccess();
            this.setState({action: 'login'});
            return true;
        }

        this.modalRecoverFail();
        return true;
    }

    modalRef1 = ({modalOpen}) => {
        this.showModal1 = modalOpen;
    }

    modalRef2 = ({modalOpen}) => {
        this.showModal2 = modalOpen;
    }

    modalRefRecoverSuccess = ({modalOpen}) => {
        this.showModalRecoverSuccess = modalOpen;
    }

    modalRefRecoverFail = ({modalOpen}) => {
        this.showModalRecoverFail = modalOpen;
    }

    modalClick1 = () => {
        this.showModal1();
    }

    modalClick2 = () => {
        this.showModal2();
    }

    modalRecoverSuccess = () => {
        this.showModalRecoverSuccess();
    }

    modalRecoverFail= () => {
        this.showModalRecoverFail();
    }

    render() {
        return (
            <>
                <section className={style.auth}>
                    <Modal ref={this.modalRef2} messages={["Usuário ou senha inválidos"]}/>
                    <Modal ref={this.modalRef1} messages={["Informações Inválidas"]}/>
                    <Modal ref={this.modalRefRecoverFail} messages={["Informe um e-mail válido"]}/>
                    <Modal ref={this.modalRefRecoverSuccess} messages={["Verifique seu e-mail para recuperar sua senha"]}/>
                    {/* <img src={Banner} className={style.header}/> */}
                    <div className={style.header}></div>
                    <div className={style.containerDefault}>
                        <div className={"row justify-content-center align-items-center " + style.login}>
                            <div className={"col-lg-7 d-flex justify-content-center align-items-center flex-column"}
                                 data-aos="fade-right">
                                <h1 className={style.h1Auth}>ACESSE E CONFIRA <br/> CONTEÚDOS EXCLUSIVOS <br/> PARA
                                    CUIDAR DOS <br/> SEUS PETS!</h1>
                            </div>
                            <div
                                className={"col-lg-5 d-flex justify-content-center align-items-center flex-column " + style.form}
                                data-aos="fade-left">
                                <h2 className={style.h2Auth + " mb-4"}>
                                    {(this.state.action === 'login') ? 'FAÇA SEU LOGIN' : 'RECUPERE SUA SENHA'}
                                </h2>
                                <form className={"row"} onSubmit={(e) => {
                                    this.handleSubmit(e)
                                }}>
                                    <div
                                        className={"col-12 d-flex justify-content-center flex-column " + style.colLine}>
                                        <label className="mb-1" htmlFor="fname">E-MAIL:</label><br/>
                                        <input onChange={(e) => {
                                            this.handleInput(e.target)
                                        }} className={style.authInput} type="text" id="username" name="username"/><br/>
                                    </div>
                                    {
                                        (this.state.action === 'login')
                                            ? <>
                                                <div
                                                    className={"col-12 d-flex justify-content-center flex-column " + style.colLine}>
                                                    <label className="mb-1" htmlFor="lname">SENHA:</label><br/>
                                                    <input onChange={(e) => {
                                                        this.handleInput(e.target)
                                                    }} className={style.authInput} type="password" id="password"
                                                           name="password"/><br/><br/>
                                                </div>
                                                <div
                                                    className={"col-12 d-flex justify-content-between align-items-center " + style.colAct}>
                                                    <button type="button" onClick={() => {
                                                        this.setState({action: 'recover'})
                                                    }} className={style.buttonRecover}>
                                                        <p>RECUPERAR SENHA</p></button>
                                                    <button type="submit" onClick={null} className={style.button}><p>PROSSEGUIR</p>
                                                    </button>
                                                </div>
                                            </>
                                            : <>
                                                <div
                                                    className={"col-12 d-flex justify-content-between align-items-center " + style.colAct}>
                                                    <button type="button" onClick={() => {
                                                        this.setState({action: 'login'})
                                                    }} className={style.buttonRecover}>
                                                        <p>Voltar</p></button>
                                                    <button type="button" onClick={(e) => this.handleRecover(e)} className={style.button}><p>RECUPERAR</p>
                                                    </button>
                                                </div>
                                            </>
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}
