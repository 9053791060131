import React  from 'react';
import style from './register.module.scss';
import './register.module.scss';
import {withRouter} from 'react-router-dom';
import InputMask from 'react-input-mask';

import Footer from '../../templates/footer/footer';
import NavBar from "../../templates/nav-bar/nav-bar";
import AbstractPage from "../AbstractPage";
import Modal from "../../organisms/modal/modal";

class Register extends AbstractPage {
    constructor(props) {
        super(props);
        this.state = {
            user: {
                name: '...',
                email: '...',
                phone: '...',
                agrees: [],
                type: 0
            },
            account: props.account,
            errorMessages: [],
            password: '',
            passwordConfirmation: '',
            checkPrivacyPolicyRC: false,
            checkPrivacyPolicy48: false,
            checkOptinSMS: false,
            checkOptinEmail: false,
            checkOptinWhatsApp: false,
            checkOptinCall: false
        }
        this.modalRef = this.modalRef.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
    }

    async componentDidMount() {
        await this.init();
        const self = this;
        this.state.user.agrees.forEach(function(agree) {
            switch (agree.agree_type.slug) {
                case "optin_sms":
                    self.setState({checkOptinSMS: agree.status === 1});
                    break;
                case "optin_email":
                    self.setState({checkOptinEmail: agree.status === 1});
                    break;
                case "optin_whats":
                    self.setState({checkOptinWhatsApp: agree.status === 1});
                    break;
                case "optin_call":
                    self.setState({checkOptinCall: agree.status === 1});
                    break;
                case "privacy_policy_rc":
                    self.setState({checkPrivacyPolicyRC: agree.status === 1});
                    break;
                case "privacy_policy_48":
                    self.setState({checkPrivacyPolicy48: agree.status === 1});
                    break;
                default:
                    break;
            }
        });
    }

    modalRef = (obj) => {
        this.showModal = obj && obj.modalOpen;
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const newValue = {
            [name]: value
        };
        this.setState(newValue);
    }

    async onSubmit(e) {
        e.preventDefault();

        const data = {
            password: this.state.password,
            password_confirmation: this.state.passwordConfirmation,
            optin_sms: this.state.checkOptinSMS,
            optin_email: this.state.checkOptinEmail,
            optin_whats: this.state.checkOptinWhatsApp,
            optin_call: this.state.checkOptinCall,
            privacy_policy_rc: true,
            privacy_policy_48: true
        };

        if(this.state.password !== "") {
            if(this.state.password !== this.state.passwordConfirmation) {
                this.setState({errorMessages: ["Senhas não correspondem"]})
                this.showModal();
                return false;
            }
        }

        try {
            if(this.state.account) {
                await this.api.put_user_account(data);
            } else {
                await this.api.put_user(data);
            }
            window.location.href = "/";
        } catch (e) {
            var errors = [];
            Object.entries(e.response.data.errors).map(([field, error], i) => {
                errors = [...errors, ...error];
                return errors;
            });
            this.setState({errorMessages: errors});
            this.showModal();
        }
    }

    render() {
        return (
            <>
                {
                    this.state.account || true
                    ? <NavBar name={this.state.user.name.split(' ')[0]} />
                    : ''
                }
                <section className={style.register + ' ' + style.containerDefault}>
                    <Modal ref={this.modalRef} messages={this.state.errorMessages || []} />
                    <div className={style.containerDefault + " text-center mt-5 p-5"}>
                        <h1 style={{marginTop: (this.state.account ? '96px' : '96px')}}>{this.state.account ? 'MEU PERFIL' : 'CADASTRO'}</h1>
                        {
                            this.state.account
                            ? <p className={"mt-3"}>MANTENHA SEUS DADOS ATUALIZADOS <br/>  PARA ACESSAR AOS VÍDEOS QUANDO QUISER </p>
                            : <p className={"mt-3"}>CONFIRME SEUS DADOS E ASSISTA A DICAS DE <br /> TREINAMENTO BÁSICO PARA SEUS PETS COM {this.state.user.type === 1 ? 'O PET MODERNO' : (this.state.user.type === 2 ? 'A ROYAL CANIN' : '...')}!</p>
                        }
                    </div>
                    <div className={style.containerDefault + " mt-2 d-flex justify-content-center"}>
                        <form className={"row d-flex justify-content-center flex-column"}>
                            <div className={style.formDiv + " col-12 d-flex"}>
                                <div className={style.fields + ' ' + style.specific1 + " d-flex flex-column"}>
                                    <label>Nome Completo</label>
                                    <input disabled={"disabled"} type="text" value={this.state.user.name} />
                                </div>
                                <div className={style.fields + ' ' + style.specific2 + " d-flex flex-column"}>
                                    <label>Telefone Celular</label>
                                    <InputMask mask="(99) 99999-9999" disabled={"disabled"} value={this.state.user.phone}>
                                        {(inputProps) => <input  type="tel" {...inputProps} disabled={"disabled"} />}
                                    </InputMask>
                                </div>
                            </div>
                            <div className={style.formDiv + " col-12 d-flex"}>
                                <div className={"d-flex flex-column"}>
                                    <label>E-mail</label>
                                    <input disabled={true} type="text" value={this.state.user.email} />
                                </div>
                            </div>
                            <div className={style.formDiv + " col-12 d-flex"}>
                                <div className={style.fields + " d-flex flex-column"}>
                                    <label>Cadastre sua senha</label>
                                    <input name={"password"} onChange={this.handleInputChange} type="password" autoComplete={"off"} />
                                </div>
                                <div className={style.fields + " d-flex flex-column"}>
                                    <label>Confirme sua senha</label>
                                    <input name={"passwordConfirmation"} onChange={this.handleInputChange} type="password" autoComplete={"off"} />
                                </div>
                            </div>
                            <p className={style.disclamer + " mt-2"}>Sua senha deve conter de 6 a 10 caracteres.</p>
                            <div className={style.checkboxDiv + " mt-3"}>
                                <p className={style.terms + " mt-5 mb-3"}>*Aceito receber novidades e informações da Royal Canin{this.state.user.type === 1 ? ' e do Pet Moderno' : (this.state.user.type === 2 ? '' : '')} via:</p>
                                <div>
                                    <input id={"acceptSms"} name={"checkOptinSMS"} defaultChecked={this.state.checkOptinSMS ? 'checked' : null} onChange={this.handleInputChange} type="checkbox" />
                                    <label htmlFor="acceptSms">SMS</label><br />
                                </div>
                                <div>
                                    <input id={"acceptEmail"} name={"checkOptinEmail"} defaultChecked={this.state.checkOptinEmail ? 'checked' : null} onChange={this.handleInputChange} type="checkbox" />
                                    <label htmlFor="acceptEmail">E-mail</label><br />
                                </div>
                                <div>
                                    <input id={"acceptWhatsApp"} name={"checkOptinWhatsApp"} defaultChecked={this.state.checkOptinWhatsApp ? 'checked' : null} onChange={this.handleInputChange} type="checkbox" />
                                    <label htmlFor="acceptWhatsApp">WhatsApp</label><br />
                                </div>
                                <div>
                                    <input id={"acceptCall"} name={"checkOptinCall"} defaultChecked={this.state.checkOptinCall ? 'checked' : null} onChange={this.handleInputChange} type="checkbox" />
                                    <label htmlFor="acceptCall">Ligação</label><br />
                                </div>
                            </div>
                            {
                                this.state.account
                                ? ''
                                : ''
                            }
                            <button className={style.submitButton} type="submit" onClick={this.onSubmit}>
                                ATUALIZAR
                            </button>
                        </form>
                    </div>
                </section>
                <Footer />
            </>
        )
    }
}

export default withRouter(Register);
