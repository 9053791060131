import style from './account.module.scss';
import React, { Component } from 'react';

import Footer from '../../templates/footer/footer';
import NavBar from '../../templates/nav-bar/nav-bar';


export default class Account extends Component {
    render() {
        return (
            <>
                <NavBar />
                <section className={style.account + ' container'}>
                    
                    <div className={"container text-center mt-5 p-5"}>
                        <h1>MEU PERFIL</h1>
                        <p className={style.h1P + " mt-3"}> MANTENHA SEUS DADOS ATUALIZADOS <br/>  PARA ACESSAR AOS VÍDEOS QUANDO QUISER </p>
                    </div>
                    <div className={"container mt-2 d-flex justify-content-center"}>
                        <form className={"d-flex justify-content-center flex-column"}>
                            <div className={style.formDiv + " d-flex"}>
                                <div className={style.fields + ' ' + style.specific1 + " d-flex flex-column"}>
                                    <label>E-mail</label>
                                    <input type="text" />
                                </div>
                                <div className={style.fields + ' ' + style.specific2 + " d-flex flex-column"}>
                                    <label>Confirme seu e-mail</label>
                                    <input type="" />
                                </div>
                            </div>

                            <div className={style.formDiv + " d-flex"}>
                                <div className={style.fields + " d-flex flex-column"}>
                                    <label>Cadastre sua senha</label>
                                    <input type="text" />
                                </div>
                                <div className={style.fields + " d-flex flex-column mb-5"}>
                                    <label>Confirme sua senha</label>
                                    <input type="" />
                                </div>
                            </div>
                            <div className={style.submitButton} type="submit">
                                <p>ATUALIZAR</p>
                            </div>
                        </form>
                    </div>
                </section>
                <Footer/>
            </>
        )
    }
}
